import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        parent: {
            position: 'relative',
            height: '100vh',
        },
        parentBackground: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#fff',
        },
        child: {
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 50%)',
            '& ul': {
                listStyle: ' none',
                margin: 0,
                padding: 0,
            }
        },
        loader: {
            height: '120px',
            width: '120px',
            marginBottom: theme.spacing(4),
            '& img': {
                position: 'relative',
                left: '50%',
                transform: 'translate(-50%, 75%)',
                width: '60px',
            }
        },
        spinner: {
            position: 'absolute',
            top: '0',
            height: '120px',
            width: '120px',
            borderWidth: '8px',
            borderStyle: 'solid',
            borderColor: '#cdcccc',
            borderRadius: '50%',
            animation: '$loader 1.5s linear infinite',
            borderTopColor: theme.palette.primary.main,
            '&.finished-spin': {
                animation: '$loader 1.5s linear infinite, $finshLoad 1s linear',
                animationFillMode: 'forwards',
            },
        },
        '@keyframes loader': {
            '100%': {
                transform: 'rotate(360deg)',
            },
        },
        '@keyframes finshLoad': {
            'to': {
                borderColor: theme.palette.primary.main,
            },
        },
        progressColour: {
            color: theme.palette.primary.main,
        },
        stages: {
            whiteSpace: 'nowrap',
            '& li': {
                margin: theme.spacing(1, 0),
            },
            '& svg': {
                verticalAlign: 'middle',
                marginRight: theme.spacing(2),
            },
        },
        tickStage: {
            animation: '$tickStage 500ms ease-in',
        },
        '@keyframes tickStage': {
            'from': {
                opacity: 0.3,
            },
            'to': {
                opactity: 1,
            }
        },
        stageText: {
            fontFamily: "Spartan MB SemiBold",
            fontSize: '0.875rem',
        },
        tickedStep: {
            color: theme.palette.text.primary,
        },
        futureStep: {
            opacity: 0.3,
        },
    })
);

type Props = {
    stages: string[];
    interval: number;
    isLoading: boolean;
    setFinishedLoading: (finishedLoading: boolean) => void;
};

const Loading = ({ stages, interval, isLoading, setFinishedLoading }: Props) => {
    const classes = useStyles();
    const [currentStage, setCurrentStage] = useState(0);
    const [finishSpin, setFinishSpin] = useState(false);
    const [finishSpinClass, setFinishSpinClass] = useState("");

    useEffect(() => {
        let timer: any;
        function runLoad() {
            timer = setTimeout(() => {
                if (currentStage + 1 === stages.length) {
                    if (!isLoading) {
                        setCurrentStage(currentStage + 1);
                        setFinishSpin(true);
                        setTimeout(() => {
                            setFinishedLoading(true);
                        }, 1000);
                    }
                } else {
                    setCurrentStage(currentStage + 1);
                }
            }, interval);
        }

        if (currentStage < stages.length) {
            runLoad();
        }

        return function cleanup() {
            clearTimeout(timer);
        };
    }, [currentStage, isLoading]);

    const handleFinishSpin = (_: React.AnimationEvent<HTMLDivElement>) => {
        if (finishSpin) {
            setFinishSpinClass('finished-spin');
        }
    };

    return (
        <>
            <div className={classes.parentBackground} />
            <div className={classes.parent}>
                <div className={classes.child}>
                    <Grid container justify="center">
                        <div className={classes.loader}>
                            <img src="/img/loading-house.png" alt="Loading House" />
                            <div className={`${classes.spinner} ${finishSpinClass}`} onAnimationIteration={handleFinishSpin}></div>
                        </div>
                    </Grid>
                    <ul className={classes.stages}>
                        {
                            stages.map((x, index) =>
                                <li key={`stage-${index}`}>
                                    {index < currentStage ?
                                        <CheckCircleIcon className={`${classes.tickStage} ${classes.progressColour}`} />
                                        :
                                        <RadioButtonUncheckedIcon className={classes.futureStep} />
                                    }
                                    <span className={`${classes.stageText} ${index < currentStage ? classes.tickedStep : classes.futureStep}`}>{x}</span>
                                </li>
                            )
                        }
                    </ul>
                </div>
            </div>
        </>
    )
};

export default Loading;
import { AppBar, Container, createStyles, Hidden, makeStyles, Theme, Toolbar, useTheme, Box } from '@material-ui/core';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { useStateValue } from '../hooks/StateContext';
import { setShowContactModal } from './Controls/Actions/HeaderActions';
import ContactModal from './Controls/ContactModal';
import Icon from './Icon';
import { navigate } from "@reach/router";
import NavigationConfirmationModal from './Controls/NavigationConfirmationModal';
import { setApplicationStateToDefault } from '../models/ApplicationStateActions';

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        offset: {
            [theme.breakpoints.only('xs')]: {
                minHeight: '56px',
            },
            [theme.breakpoints.up('sm')]: {
                minHeight: theme.header.minHeight ? `${theme.header.minHeight}px` : '64px',
            },
        },
        iconArrow: {
            fontSize: "1.125rem",
            fontWeight: "bold",
            color: theme.header.textColor,
        },
        headerLogoDiv: {
            [theme.breakpoints.up('sm')]: {
                flexGrow: 1,
                alignSelf: theme.logoVerticalAlignment,
            },
        },
        appbar: {
            backgroundColor: theme.header.backgroundColor,
        },
        toolbar: {
            [theme.breakpoints.only('xs')]: {
                minHeight: '56px',
            },
            [theme.breakpoints.up('sm')]: {
                minHeight: theme.header.minHeight ? `${theme.header.minHeight}px` : '64px',
            },
        },
        backLink: {
            color: theme.header.textColor,
            minWidth: '1.125rem',
        },
        headerSides: {
            flexGrow: 1,
            alignSelf: 'center',
        },
        telephone: {
            flexGrow: 1,
            textAlign: 'right',
            [theme.breakpoints.up('sm')]: {
                alignSelf: theme.telephoneHelpVerticalAlignment,
                margin: '20px 0',
            }
        },
        styledLink: {
            color: theme.header.textColor,
            fontFamily: "Spartan MB Bold",
            fontSize: "0.6875rem",
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'none',
            },
        },
        defaultText: {
            display: 'block',
            cursor: 'pointer',
            color: theme.header.textColor,
            fontSize: "0.6875rem",
        },
        desktopText: {
            fontSize: '1.125rem',
        },
        logo: {
            [theme.breakpoints.up('sm')]: {
                height: `${40 * theme.metadata.logo.scale}px`,
                margin: '20px 0',
            },
            height: '40px',
            '&:hover': {
                cursor: 'pointer',
            },
        },
        operatedByLogo: {
            [theme.breakpoints.up('sm')]: {
                height: '36px',
                margin: '10px 0',
            },
            height: '30px',
        },
    });
});

type Props = {
    hideBackButton?: boolean;
};

const Header = ({ hideBackButton }: Props) => {
    const classes = useStyles();
    const theme = useTheme();
    const { state, dispatch } = useStateValue();
    const [showNavigationConfirmationModal, setShowNavigationConfirmationModal] = useState<boolean>(false);

    const handleClick = (event: React.SyntheticEvent) => {
        event.preventDefault();
        history.back();
    };

    useEffect(() => {
        const handleBeforeUnload = (e: any) => {
            e.preventDefault();
            e.returnValue = true;
        }
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    });

    const handleOpen = () => {
        dispatch(setShowContactModal(true));
    };

    const handleClose = () => {
        dispatch(setShowContactModal(false));
    };

    const handleOpenNavigationConfirmation = (e: any) => {
        if (!state.IsApplicationComplete) {
            e.preventDefault();
            setShowNavigationConfirmationModal(true);
        }
        else {
            dispatch(setApplicationStateToDefault());
        }
    };

    const handleNavigationContinue = () => {
        dispatch(setApplicationStateToDefault());
        navigate('/');
    };

    const handleNavigationCancel = () => {
        setShowNavigationConfirmationModal(false);
    };

    return (
        <>
            <AppBar position="fixed" className={classes.appbar}>
                <Container maxWidth={!theme.header.fullWidth ? 'md' : 'xl'}>
                    <Toolbar disableGutters className={classes.toolbar}>
                        <Hidden smUp>
                            <div className={`${classes.headerSides} ${classes.backLink}`}>
                                {
                                    !hideBackButton &&
                                    <Link onClick={handleClick} data-testid="back-button" className={classes.styledLink} to='/'>
                                        <Icon icon="Arrow" cssProperties={classes.iconArrow} />
                                    </Link>
                                }
                            </div>
                        </Hidden>
                        <Link onClick={handleOpenNavigationConfirmation} to='/'>
                            <div className={classes.headerLogoDiv}>
                                <img src={theme.metadata.logoUrl} className={classes.logo} />
                            </div>
                        </Link>
                        <div className={`${classes.headerSides} ${classes.telephone}`}>
                            <Hidden smUp>
                                <a className={classes.styledLink} onClick={handleOpen}>Need help?</a>
                            </Hidden>
                            <Hidden only={'xs'}>
                                <a onClick={handleOpen} className={classes.defaultText}>Need help?</a>
                                <a href={`tel:${theme.metadata.phoneNumber}`} className={`${classes.styledLink} ${classes.desktopText}`}>
                                    {theme.metadata.phoneNumber}
                                </a>
                            </Hidden>
                            {
                                theme.metadata.operatedByLogoUrl &&
                                <Box>
                                    <img src={theme.metadata.operatedByLogoUrl} className={classes.operatedByLogo} />
                                </Box>
                            }
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>
            <div className={classes.offset} />
            <ContactModal
                open={state.ShowContactModal}
                title="We're here to help."
                content={`Our UK based team of insurance experts are here to help. How would you like to get in touch?`}
                onClose={handleClose}
                showCloseButton={false}
            />
            <NavigationConfirmationModal
                open={showNavigationConfirmationModal}
                title="Are you sure?"
                content='You will lose your quote if you return home'
                onContinue={handleNavigationContinue}
                onClose={handleNavigationCancel}
            />
        </>
    )
};

export default Header;
import React, { useState, useEffect } from 'react';
import Loading from '../components/Controls/Loading';
import Header from '../components/Header';

const LoadingPage = () => {
    const [loading, setLoading] = useState(false);
    const [finishedLoading, setFinishedLoading] = useState(false);
    const loadingStages = ["Uploading your details", "Finding your home", "Checking property details", "Loading your details"];

    useEffect(() => {
        async function runSetLoading() {
            setLoading(true);
            await new Promise(resolve => setTimeout(resolve, 3000));
            setLoading(false);
        }
        runSetLoading();
    }, [])

    return (
        <>
            <Header />
            <Loading stages={loadingStages} interval={500} isLoading={loading} setFinishedLoading={setFinishedLoading} />
        </>
    );
};

export default LoadingPage;